// Here you can add other styles
.c-app {
    background-color: map-get($theme-colors, "dark");
}

.c-sidebar {
    background-color: $black;

    .c-sidebar-brand {
        background-color: transparent;
    }

    .c-sidebar-nav-link.c-active, .c-sidebar .c-active.c-sidebar-nav-dropdown-toggle {
        background: map-get($theme-colors, "primary");
    }

    &-brand-full, &-brand-minimized {
        height: 35px;
    }
}

.c-header-brand {
    img {
        height: 48px;
    }
}

.react-tags {
    @extend .mw-100;
}

.react-tags.is-focused {
    border-color: #B1B1B1;
}
  
.react-tags__selected {
    display: inline;
}
  
.react-tags__selected-tag {
    @extend .p-2;
    @extend .mr-2;

    display: inline-block;
    box-sizing: border-box;
    border: 0;
    border-radius: 0.25rem;
    background: map-get($theme-colors, "light");
    font-size: inherit;
    line-height: inherit;
}
  
.react-tags__selected-tag:after {
    content: '\2715';
    color: #AAA;
    margin-left: 8px;
}
  
.react-tags__selected-tag:hover,
.react-tags__selected-tag:focus {
    border-color: #B1B1B1;
}
  
.react-tags__search {
    display: inline-block;
  
    /* match tag layout */
    padding: 7px 2px;
    margin-bottom: 6px;
  
    /* prevent autoresize overflowing the container */
    max-width: 100%;
}
  
@media screen and (min-width: 30em) {
    .react-tags__search {
        /* this will become the offsetParent for suggestions */
        position: relative;
    }  
}
  
.react-tags__search-input {
    @extend .form-control;

    max-width: 100%;
}
  
.react-tags__search-input::-ms-clear {
    display: none;
}
  
.react-tags__suggestions {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
}
  
@media screen and (min-width: 30em) {
    .react-tags__suggestions {
        width: 240px;
    }
}
  
.react-tags__suggestions ul {
    margin: 4px -1px;
    padding: 0;
    list-style: none;
    background: white;
    border: 1px solid #D1D1D1;
    border-radius: 2px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}
  
.react-tags__suggestions li {
    border-bottom: 1px solid #ddd;
    padding: 6px 8px;
}
  
.react-tags__suggestions li mark {
    text-decoration: underline;
    background: none;
    font-weight: 600;
}
  
.react-tags__suggestions li:hover {
    cursor: pointer;
    background: #eee;
}
  
.react-tags__suggestions li.is-active {
    background: #b7cfe0;
}
  
.react-tags__suggestions li.is-disabled {
    opacity: 0.5;
    cursor: auto;
}

.login-logo {
    max-width: 100%;
}

.nav-tabs {
    flex-wrap: nowrap;
    white-space: nowrap;
    overflow-x: auto;

    .nav-link {
        font-weight: bold;
    }

    .nav-link.active, .nav-item.show .nav-link {
        background-color: map-get($theme-colors, "primary");
        color: white;
        border-color: transparent;
    }
}

.draggable {
    cursor: grab;
}

.dragging {
    opacity: 0.5;
}

.dragover {
    background-color: rgba(map-get($theme-colors, "primary"), 0.25) !important;
}

.taps, .users {
    position: relative;

    .download {
        position: absolute;
        top: 50%;
        right: 1rem;
        transform: translateY(-50%);
    }
}

.logout {
    cursor: pointer;
}

.nowrap {
    white-space: nowrap;
}