// Variable overrides
$black: rgb(9, 10, 11);
$dark-gray: rgb(54, 54, 54);
$orange: rgb(249, 103, 61);

$body-bg: $dark-gray;

$theme-colors: (
    "primary": $orange,
    "dark": $dark-gray
);